import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import phoneframe from '../assets/img/phone-frame.png';
import SmartContainer from '../layout/SmartContainer';

import happyFamily from '../assets/img/img-happy-family.jpg';
import flashing from '../assets/img/exterior-flashing-lights.gif';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import btn911 from '../assets/img/911-button.png';
import VideoBackground from '../layout/VideoBackground';
import lightFlash from '../assets/vid/knowtifi-demo.mp4';
import KnowtifiIntro from '../layout/KnowtifiIntro';
import productBundle from '../assets/img/911Switch-product-bundle-transparent.png';

const HomeNew = (props: any) => {
    const {} = props;

    return (
        <>
            <Helmet>
                <meta name="description" content="The knowtifi mobile app allows you to contact 911, share your location, and alert your friends and family all at once." />
                <title>knowtifi : Safety in the palm of your hand</title>
                <link rel="canonical" href="https://knowtifi.com"/>
            </Helmet>

                <KnowtifiIntro video={lightFlash} />

            <div className="bg-gray section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h2 style={{marginBottom:'30px'}}><span className="k">knowtifi &amp; 911Switch</span><br/>The superior smart home safety system</h2>
                            <div className="text-up">
                                <p>In an emergency situation, every second counts.</p>
                                <p>When you pair the <b>knowtifi</b> mobile app with the <b>911Switch</b> smart switch, your home is ready for any emergency. Use the knowtifi app
                                    to initiate a call to 911 and the app will immediately notify your emergency contacts of your emergency and trigger 911Switch, a smart home device
                                    that turns your home into a flashing beacon helping emergency personnel locate your home.</p>
                                <p>Knowtifi is truly safety in the palm of your hand.</p>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-5">
                            <img src={productBundle} className="img-fluid" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h2 className={'download-now'}><a href={"https://apple.com"} target="_blank"><i className="fa-regular fa-mobile-notch"></i> Download now on iOS and Android</a></h2>
                        </div>
                        <div className="d-none d-lg-block col-lg-5">
                            <a href="/911switch/buy" className="big-btn"><i
                                className="fa-solid fa-bag-shopping"></i> &nbsp; Buy Now</a>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
            <SmartContainer>
                <div className="row">
                    <div className="col-12">
                        <h2 style={{marginBottom:'30px'}}><span className="k">Inform & Be Informed</span><br/>Introducing the knowtifi Mobile App</h2>
                        <div className="columns-2">
                            <p>Everyone knows when an emergency happens, seconds matter. The faster you get help, the better. From domestic disturbances to weather events to car accidents or anything in between, getting help fast can be lifesaving.</p>
                            <p>Once an emergency occurs, people usually call 911, then:</p>
                            <ol>
                                <li>First responders are alerted and dispatched to the scene to provide aid</li>
                                <li>If necessary, transport is provided to hospitals</li>
                                <li>Only later are family and others informed</li>
                            </ol>
                            <p>The knowtifi app changes everything.</p>
                            <p>knowtifi is the world’s first app available on your Apple or Android device that with a tap of the app contacts 911, family and friends all at the same time. With knowtifi:</p>
                            <ol>
                                <li>911 is contacted and first responders are dispatched</li>
                                <li>Simultaneously, your family members, trusted friends, neighbors or others, also are notified. This network-wide alerts notifies multiple people and police, fire or ambulance at once – giving you the greatest opportunity for rapid response.</li>
                            </ol>
                            <p>knowtifi is different is different for a reason.</p>
                            <p>Don’t delay. Download the knowtifi app today. After all, your life and the life of your loved ones depend on it.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h1 className="hows-it-work">How Does <span className="k">knowtifi</span> Work?</h1>
                        <p className="hows-it-work-sub">Using the knowtifi app is simple. It's designed that way. After all, in an emergency, you or those you care about need to get help fast.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 text-center hows-it-work-item">
                        <img src={btn911} />
                        <h3>1. Make the call</h3>
                        <p>Open the knowtifi app and tap the 911 icon to initiate a call to 911 or to start a chat session with knowtifi dispatchers.</p>
                    </div>
                    <div className="col-12 col-md-4 text-center hows-it-work-item">
                        <div className="icon">
                            <i className="fa-solid fa-users"></i>
                        </div>
                        <h3>2. Alert loved ones</h3>
                        <p>Your emergency contacts are immediately notified with an app notification or text message on their phones.</p>
                    </div>
                    <div className="col-12 col-md-4 text-center hows-it-work-item">
                        <div className="icon">
                            <i className="fa-sharp fa-solid fa-street-view"></i>
                        </div>                        <h3>3. Share smart</h3>
                        <p>Your GPS location is shared with 911 and your emergency contacts. 911 responds and first responders dispatched.</p>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-12">*/}
                {/*        <h2 className="top-border" style={{marginBottom:'30px'}}><span className="k">911Switch</span> Lights the Way to Safety</h2>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="row">*/}
                {/*    <div className="col-12 col-md-6">*/}
                {/*        <div className="text-up">*/}
                {/*            <p>In addition to your trusted contacts being notified by the knowtifi app, adding a 911Switch – a rapid alert light – alerts emergency crews and others who can render aid. In an emergency, visibility is crucial for first responders.</p>*/}
                {/*            <p><Link to={'/911switch'}>Learn more about the 911Switch – Powered by knowtifi</Link>*/}
                {/*                &nbsp; &nbsp;<i className="fa-sharp fa-solid fa-caret-right"></i><i className="fa-sharp fa-solid fa-caret-right"></i></p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-12 col-md-6">*/}
                {/*        <img src={flashing} className="img-fluid" />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </SmartContainer>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(HomeNew);

